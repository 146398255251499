import $ from 'jquery';

$(document)
  .on('change', '.js-select-rejection-message-template', () => {
    $('.js-select-rejection-message-template-target')[0].value = $('.js-select-rejection-message-template option:selected').data('content');
});

$(() => {
  const ele = $('.js-select-rejection-message-template-target');
  if (ele.length === 0) return;

  if (ele[0].value === '') {
    $('.js-select-rejection-message-template').trigger('change');
  }
});

