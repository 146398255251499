import $ from 'jquery';

const FIT_CONTENT_MAX_HEIGHT = 450;

// Facebookみたいに改行に合わせて伸びるフォーム
// http://qiita.com/mizchi/items/c39fd4b532f702c22d18
const fitToContent = (el, maxHeight) => {
  let adjustedHeight = el.clientHeight;

  if (!maxHeight || maxHeight > adjustedHeight) {
    adjustedHeight = Math.max(el.scrollHeight, adjustedHeight);

    if (maxHeight) {
      adjustedHeight = Math.min(maxHeight, adjustedHeight);
    }

    if (adjustedHeight > el.clientHeight) {
      // eslint-disable-next-line no-param-reassign
      el.style.height = `${adjustedHeight}px`;
    }
  }
};

$(document).on('input focus', '.js-autosize', ({ currentTarget }) => {
  const maxHeight = $(currentTarget).data('maxHeight') || FIT_CONTENT_MAX_HEIGHT;
  fitToContent(currentTarget, maxHeight);
});

$(() => $('.js-autosize').trigger('input'));
