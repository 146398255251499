import $ from 'jquery';
import Clipboard from 'clipboard';
import { notifySuccess, notifyError } from '../../lib/notify';

const generateEmailsText = () => {
  const emails =
    Array.from(document.querySelectorAll('.js-target-group:checked')).map((target) => {
      return target.value;
    });
  return emails.join(', ');
};

$(() => {
  const clipboard = new Clipboard('.js-copy-group-emails', {
      text: () => { return generateEmailsText(); }
    }
  );

  clipboard.on('success', () => {
    notifySuccess('コピーしました');
  });
});
