import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["recentlyMessage", "allMessage", "displayAll", "displayRecently"];

  recently() {
    this.displayRecentlyTarget.classList.add('d-none')
    this.displayAllTarget.classList.remove('d-none')

    this.allMessageTargets.forEach((message) => {
      message.classList.add('d-none')
    })
  }

  all() {
    this.displayAllTarget.classList.add('text-muted', 'text-decoration-none')
    this.allMessageTargets.forEach((message) => {
      message.classList.remove('d-none')
    })
  }
}
