// NOTE: YubinBangoライブラリと同様の動作するようにYubinBango-Coreを使って自前で実装(オリジナルは更新止まっていたり、数値以外を入力するとエラーになったりと問題あり)
// https://github.com/yubinbango/yubinbango
// https://github.com/yubinbango/yubinbango-core

import $ from 'jquery';
import { notifyError, notifyErrors } from './notify';
import YubinBango from 'yubinbango-core';

const FORM_SELECTOR = '.h-adr';
const POSTAL_CODE_SELECTOR = '.p-postal-code';
const HADR_MAPPING = {
  region: 'p-region',
  locality: 'p-locality',
  street: 'p-street-address',
  extended: 'p-extended-addres',
};

const postalCode2Address = postalCode =>
  new Promise(
    (resolve, reject) =>
      new YubinBango.Core(postalCode, (addr) => {
        if (addr.region_id === '') {
          return reject();
        }
        return resolve(addr);
      }),
  );

const clearAddress = (form) => {
  Object.values(HADR_MAPPING).forEach((className) => {
    const input = form.querySelector(`.${className}`);
    if (input) {
      input.value = '';
    }
  });
};

const inputAddress = (form, address) => {
  Object.keys(HADR_MAPPING).forEach((key) => {
    const className = HADR_MAPPING[key];
    const input = form.querySelector(`.${className}`);
    if (input) {
      input.value += address[key];
    }
  });
};

const onChange = async ({ currentTarget }) => {
  const form = currentTarget.closest(FORM_SELECTOR);
  const postalCode = currentTarget.value;

  if (!form) {
    return;
  }

  if (!postalCode.match(/^\d{3}-?\d{4}$/)) {
    return;
  }

  try {
    const address = await postalCode2Address(postalCode);
    clearAddress(form);
    inputAddress(form, address);
  } catch (e) {
    notifyError('該当する郵便番号が見つかりませんでした。');
  }
};

$(document).on('input', POSTAL_CODE_SELECTOR, onChange);
