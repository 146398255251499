import $ from 'jquery'
import { onConnect } from '@sonicgarden/onconnect'

onConnect('.js-popover', (el) => {
  $(el).popover()
})

onConnect('.js-popover-no-sanitize', (el) => {
  $(el).popover({ sanitize: false })
})
