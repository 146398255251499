import $ from 'jquery';

$(document)
  .on('change', '.js-select-message-template', () => {
    let content = $('.js-select-message-template option:selected').data('content');
    let guide_message =$('.js-select-message-template option:selected').data('guide-message');
    // NOTE: 'お選びください'の場合はundefined
    if( typeof content === 'undefined') { content = '' }
    if( typeof guide_message === 'undefined') { guide_message = '' }

    let $target = $('.js-select-message-template-target');
    if ($target[0].value !== '' && !confirm('入力されたメッセージ内容が破棄されますがよろしいですか？')) return;

    if ($target.hasClass('js-is-content-present')) {
      // NOTE: 入力済みの値がある場合は初回は内容をそのまま表示するが、2回目以降はセレクトボックスで選ばれた値で上書きするためにremoveClassしている
      $target.removeClass('js-is-content-present');
    } else {
      $target[0].value = content;
    }

    const $guide_ele = $('.js-select-message-template-target-guide');
    $guide_ele[0].innerText = guide_message;
    $guide_ele.toggleClass('d-none', guide_message === '');
});

$(() => {
  const ele = $('.js-select-message-template-target');
  if (ele.length === 0) return;

  if (ele[0].value === '') {
    $('.js-select-message-template').trigger('change');
  }
});

// メッセージ送信フォームでバリデーションに引っかかった時のため
$(document).on('ajax:success', 'form[data-replaces=".js-message-form"]', () => {
  $('.js-select-message-template').trigger('change');
});

$(document).on('click', '.js-link-to-message-tab', () => {
  $('#action-tab a[href="#tab-pane-message"]').tab('show');
});

$(document).on('click', '.js-message-content-more', ({currentTarget}) => {
  $(currentTarget).toggleClass('d-none');
  $(currentTarget).closest('.js-message-container').children('.js-message-content-overflow-hidden').css('overflow', 'initial').css('height', 'initial');
});
