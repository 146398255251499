import $ from 'jquery';

const toggleProjectScreeningAction = () => {
  Array.from(document.querySelectorAll('.js-toggle-disabled')).forEach((ele) => {
    $(ele.dataset.target).prop('disabled', !ele.checked);
  })
};

$(document).on('change', '.js-toggle-disabled', () => {
  toggleProjectScreeningAction();
});
$(() => {
  toggleProjectScreeningAction();
});
