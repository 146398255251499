import $ from 'jquery';

$(document)
  .on('click', '.js-project-invitation-form-clear-message', ({ currentTarget }) => {
    const ele = document.getElementsByClassName('js-project-invitation-form-message')[0]
    if (ele) {
      ele.value = '';
    }
  }
);
