import { onConnect } from '@sonicgarden/onconnect'

onConnect('.event-form-description-container trix-editor', ( element) => {
  // 画像アップロードできないように
  element.addEventListener('trix-file-accept', (event) => {
    event.preventDefault()
  })
})

onConnect('.trix-content a', ( element) => {
  // リッチテキストのリンクは別タブに
  element.setAttribute('target','_blank')
})
