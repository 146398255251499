import $ from 'jquery';

const toggleUserProfileForeignAddressInput = () => {
  const ele = document.getElementsByClassName('js-user-foreign-address')[0]
  if (ele) {
    $('.js-user-foreign-address-input').prop('disabled', ele.checked)
  }
};

$(document).on('change', '.js-user-foreign-address', () => {
  toggleUserProfileForeignAddressInput();
});

$(() => {
  toggleUserProfileForeignAddressInput();
});
