import $ from 'jquery';

const showPreview = (previewTarget, file) => {
  if (!previewTarget) {
    return;
  }
  const filereader = new FileReader();
  filereader.onload = () => {
    previewTarget.setAttribute('src', filereader.result);
  };
  filereader.readAsDataURL(file);
};

$(document).on('change', '.js-input-file-with-preview', ({ currentTarget }) => {
  const file = currentTarget.files[0];

  if (!file) {
    return;
  }

  const previewTarget = document.querySelector(currentTarget.dataset.previewTarget);
  showPreview(previewTarget, file);
});

$(document).on('change', '.js-input-file-with-sibling-preview', ({ currentTarget }) => {
  const file = currentTarget.files[0];

  if (!file) {
    return;
  }

  //NOTE: DOM構造に依存しているが、cocoonで動的に追加されるフォームなので、複数同じ要素ができてしまい、他に良い方法が見つからず、、
  const previewTarget = currentTarget.parentNode.nextElementSibling;

  showPreview(previewTarget, file);
});
