import $ from 'jquery';
import Chart, { ChartConfiguration } from 'chart.js/auto'

$(() => {
  Array.from(document.querySelectorAll('.js-pie-chart')).forEach((element: HTMLCanvasElement) => {
    const config: ChartConfiguration = {
      type: 'pie',
      data: {
        labels: element.dataset.labels.split(','),
        datasets: [{
          label: '',
          data: element.dataset.values.split(','),
          backgroundColor: element.dataset.colors.split(',')
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
        },
      },
    }
    new Chart(element, config);
  })
})
