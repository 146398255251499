import $ from 'jquery';
import {notifyError} from './notify';

const replacePartial = (selectors, html) => {
  const $parsedHtml = $($.parseHTML(html.trim()));
  [].concat(selectors).forEach((selector) => {
    // トップレベル（自分自身）は$.find対象にならないので、トップレベル要素が対象であっても抽出されるように、addBackしている
    const $partialElement = $parsedHtml.find(selector).addBack(selector);
    $(selector).html($partialElement.html() || '');
  });
};

$(document).on('ajax:success', 'form[data-replaces], a[data-replaces]',   ({ currentTarget }, html) => {
    replacePartial($(currentTarget).data('replaces'), html)
  },
)


$(document).on('click', '.js-remote-modal-partial', ({ currentTarget }) => {
  const url = $(currentTarget).data('url');
  $.ajax({ url, type: 'GET' }).then((html) => {
    replacePartial($(currentTarget).data('replaces'), html);
  });
});

export { replacePartial };
