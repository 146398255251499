import { notifySuccess, notifyError } from './notify';

export default function () {
  const messages = window.APP.flash;

  Object.keys(messages).forEach((name) => {
    const message = messages[name];
    if (typeof message !== 'string') return;
    if (name === 'notice') {
      notifySuccess(message);
    } else {
      notifyError(message);
    }
  });
}
