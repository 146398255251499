import $ from 'jquery';

const toggleSuperAdminQuestionTemplateFields = () => {
  const selectedType = $('.js-super-admin-question-template-question-type').val();

  let choicesEnabled = ['radio_buttons', 'check_boxes', 'multiple_questions'].includes(selectedType);
  $('.js-super-admin-question-template-choices').prop('disabled', !choicesEnabled);

  choicesEnabled = (selectedType === 'multiple_questions');
  $('.js-super-admin-question-template-questions').prop('disabled', !choicesEnabled);
};

$(document).on('change', '.js-super-admin-question-template-question-type', () => {
  toggleSuperAdminQuestionTemplateFields();
});
$(() => {
  toggleSuperAdminQuestionTemplateFields();
});
