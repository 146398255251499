import $ from 'jquery';

const toggleChooseAreaName = () => {
  Array.from(document.querySelectorAll('.js-mail-magazine-setting-check-all-area-name')).forEach((ele) => {
    const targetEle = ele.closest(ele.dataset.target)
    ele.checked ? targetEle.classList.add('is-all-checked') : targetEle.classList.remove('is-all-checked');
  })
};

$(document).on('change', '.js-mail-magazine-setting-check-all-area-name', () => {
  toggleChooseAreaName();
});

$(() => {
  toggleChooseAreaName();
});
