import 'core-js/stable';
import 'bootstrap/dist/js/bootstrap'
// https://github.com/joerodrig/cocoon-js
import 'cocoon-js'

import '@/lib/jquery'
import 'jquery-ujs'
import 'jquery-ujs-plus'
import '@/lib/autosize'
import '@/lib/check_all'
import '@/lib/chart'
import '@/lib/input_file_with_preview'
import '@/lib/form'
import '@/lib/loading'
import '@/lib/yubinbango_compact'
import '@/lib/popover'
import '@/lib/registerServiceWorker'
import '@/lib/remote_partial'
import '@/pages/flash_message'
import '@/pages/forcibly_modal'
import '@/pages/groups/events'
import '@/pages/groups/messages'
import '@/pages/groups/project_wizard'
import '@/pages/groups/rejection_messages'
import '@/pages/groups/project_invitation'
import '@/pages/region_admins/groups'
import '@/pages/region_admins/project_screening'
import '@/pages/super_admins/question_templates'
import '@/pages/users/profiles'
import '@/pages/users/mail_magazine_setting'

import { Turbo } from '@hotwired/turbo-rails'
import '@/controllers'
import '@sonicgarden/auto-submit-form-element'
import "trix"
import "@rails/actiontext"

Turbo.session.drive = false

