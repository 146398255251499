import $ from "jquery";


$(document).on('change', '.js-project-wizard-image-file', ({ currentTarget }) => {
  const file = currentTarget.files[0];

  if (!file) {
    return;
  }

  const previewTarget = document.querySelector(currentTarget.dataset.previewTarget);
  const filereader = new FileReader();
  filereader.onload = () => {
    previewTarget.setAttribute('src', filereader.result);
  };
  filereader.readAsDataURL(file);
  $('.js-project-wizard-upload-more').removeClass('d-none');
});
